import Vue from 'vue';
import JobsSearchMixin from './vue/jobs_search_mixin';
import EntryMemberFormForJobDetailsHorizonSlideMixin from './vue/entry_member_form_for_job_details_horizon_slide_mixin';
import HorizontalStep from './vue/entry_members/horizontal-step';
import InquiryFormMixin from './vue/inquiry_form_mixin';
import ClientMessageMixin from './vue/client_message_mixin';
import entryPanelMixin from './vue/entry_panel_mixin';
import AutocompleteMixin from './vue/autocomplete_mixin';
import ResumeMixin from './vue/resume_mixin';

document.addEventListener('DOMContentLoaded', () => {
  const vueParams = {
    el: '#body_wrapper',
    mixins: [],
    data: { searchFormState: null },
  };
  const path = window.location.pathname;

  if (path === '/') {
    vueParams.mixins.push(JobsSearchMixin);
    vueParams.mixins.push(AutocompleteMixin);
  }
  if (path === '/entry_member') {
    vueParams.mixins.push(HorizontalStep);
  } else if (path.search(/\/jobs\/\d+/) !== -1 || path === '/service') {
    vueParams.mixins.push(EntryMemberFormForJobDetailsHorizonSlideMixin);
  }
  if (path === '/clients/messages') {
    vueParams.mixins.push(ClientMessageMixin);
  }

  if ($('#entry-panel').length > 0) {
    vueParams.mixins.push(entryPanelMixin);
  }

  if ($('#resume').length > 0) {
    vueParams.mixins.push(ResumeMixin);
  }

  if (path === '/inquiry_lp_ai') {
    vueParams.mixins.push(InquiryFormMixin);
  }

  new Vue(vueParams);
});
